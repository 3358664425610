<template>
  <div id="center">
	<div class="title_bg">
	<p>数据支撑系统</p>
	</div>
    <div class="up">
		<div
		class="bg-ls item"
		v-for="item in titleItem"
		:key="item.title"
		>
		<div class="divsj">
		<img src="../assets/sj_icon.png"/>
		<p class="name">{{ item.title }}</p>
		</div>
		</div>
		
		<div
		class="bg-ls item"
		v-for="item in titleItems"
		:key="item.title"
		>
		<div class="divsj">
		<img src="../assets/sj_icon.png"/>
		<p class="name">{{ item.title }}</p>
		</div>
		</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      titleItem: [
				{
				title: 'COG游戏发行中枢平台',
				number: {
				number: [5900],
				textAlign: 'left',
				content: '{nt}',
				style: {
				fontSize: 26
				}
				}
				},
				{
				title: '四川游戏动漫版权服务平台',
				number: {
				number: [1280],
				textAlign: 'left',
				content: '{nt}',
				style: {
				fontSize: 26
				}
				}
				},
				{
				title: '四川游戏研发出版运营综合服务平台',
				number: {
				number: [15],
				textAlign: 'left',
				content: '{nt}',
				style: {
				fontSize: 26
				}
				}
				},
				{
				title: '四川省大数据中心',
				number: {
				number: [15],
				textAlign: 'left',
				content: '{nt}',
				style: {
				fontSize: 26
				}
				}
				},
				{
				title: '知信链',
				number: {
				number: [15],
				textAlign: 'left',
				content: '{nt}',
				style: {
				fontSize: 26
				}
				}
				},
				],
		titleItems: [
				{
				title: '成都农商银行资金平台',
				number: {
				number: [15],
				textAlign: 'left',
				content: '{nt}',
				style: {
				fontSize: 26
				}
				}
				},
				{
				title: '中国银行资金平台',
				number: {
				number: [15],
				textAlign: 'left',
				content: '{nt}',
				style: {
				fontSize: 26
				}
				}
				}
				,
				{
				title: '天津银行资金平台',
				number: {
				number: [15],
				textAlign: 'left',
				content: '{nt}',
				style: {
				fontSize: 26
				}
				}
				},
				{
				title: '新网银行资金平台',
				number: {
				number: [15],
				textAlign: 'left',
				content: '{nt}',
				style: {
				fontSize: 26
				}
				}
				},
				{
				title: '天府信用通',
				number: {
				number: [15],
				textAlign: 'left',
				content: '{nt}',
				style: {
				fontSize: 26
				}
				}
				}

				],
    }
  }
}
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
	.title_bg{
	width: 349px;
	height: 40px;
	background-image: url("../assets/c_title.png");
	background-size: cover;
	background-position: center center;
	line-height: 40px;
	margin-bottom: 16px;
	p{
	margin-left: 32px;
	font-size: 19px;
	background-image: linear-gradient(to bottom, #ffffff, #91dbfd);
	-webkit-background-clip: text;
	color: transparent;
	font-weight: bold;
	}
	}
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
	height: 157px;
	background-image: url("../assets/sj_bg.png");
	background-size: cover;
	background-position: center center;
	.divsj{
		width: 145px;
		height: 42px;
		display: flex;
		align-items: center;
	}
	.divsjs{
		width: 108px;
		height: 42px;
		display: flex;
		align-items: center;
	}
	
	.item {
	width: 20%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-wrap: wrap;
	align-items: center;
	.name{
		color: #c0e5fe;
		font-size: 14px;
		margin-left: 12px;
	}
	.num {
		width: 150px;
		height: 30px;
		margin-top: 20px;
		margin-left: 96px;
		}
	}
	
	
	.item:nth-of-type(2){
		width: 22% !important;
	}
	
	.item:nth-of-type(3){
		width: 22% !important;
	}
	
	.item:nth-of-type(4){
		width: 22% !important;
	}
	.item:nth-of-type(5){
		width: 14% !important;
	}
	.item:nth-of-type(9){
		width: 20% !important;
	}
	.item:nth-of-type(6){
		width: 20% !important;
	}
	.item:nth-of-type(7){
		width: 20% !important;
	}
	.item:nth-of-type(8){
		width: 20% !important;
	}
	.item:nth-of-type(3) .divsj{
		width: 160px !important;
	}
	// .item:nth-of-type(6) .divsj{
	// 	width: 108px !important;
	// }
	.item:nth-of-type(7) .divsj{
	width: 108px !important;
	}
	.item:nth-of-type(8) .divsj{
	width: 108px !important;
	}
	.item:nth-of-type(9) .divsj{
	width: 108px !important;
	}
	.num3{
		width: 150px;
		height: 30px;
		margin-top: 20px;
		margin-left: 124px;
	}
  }
  .down {
    padding: 6px 4px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 255px;
    justify-content: space-between;
   
    .ranking {
      padding: 10px;
      width: 59%;
      .dv-scr-rank-board {
        height: 225px;
      }
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 120px;
        span {
          margin-top: 8px;
          font-size: 14px;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
        .dv-wa-le-po {
          height: 120px;
        }
      }
    }
  }
}
</style>
